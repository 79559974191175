<template>
  <router-view />
</template>

<script>

export default {
  name: "BuildingAutomation",
  data() {
    return {};
  },
  mounted() {
  },
  unmounted() {

  },
  methods: {}
};
</script>

<style scoped>
.info_col {
  font-size: 12px;
  color: #30D6C2;
}

.info {
  width: calc(100% - 20px);
  height: 60px;
  border: 1px solid #30D6C2;
  margin-left: 10px;
  background-color: rgba(14, 40, 37, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-around;
}

.info_row {
  display: flex;
  justify-content: space-between;
}

.bg {
  background-image: linear-gradient(to right, rgba(88, 173, 169, 0.7) 0%, rgba(19, 21, 21, 0.1) 100%);
}

.search {
  position: absolute;
  right: 15px;
  top: -10px;
  width: 150px;
  height: 35px;
}

.search input {
  width: calc(100% - 30px);
  height: 100%;
  padding: 0 15px;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
}

.warn_container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
}

.warn_item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  margin: 10px 0;
}

.warn_item .warn_text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.warn_title_text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.warn_title {
  width: 100%;
  height: 37.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #4CB0A4;
  /*background-image: radial-gradient(rgba(88, 173, 169, 0.6), rgba(19, 21, 21, 0.1));*/
}

.video_name {
  color: #fff;
}

.video {
  margin-bottom: 15PX;
}


.video_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pie_num {
  width: 80px;
  height: 40px;
  color: #30D6C2;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 53px;
  top: 80px;
}

.pie_label {
  width: 100px;
  height: 25px;
  position: absolute;
  left: 43px;
  bottom: 3px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 14px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 160px;
  right: 10px;
  top: 42px;
}

.pie_con {
  width: 100%;
  position: relative;
}


.floor {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.floors {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  height: 160px;
  overflow: scroll;
}

.whirling {
  transform: rotateZ(-180deg);
}

.select_:first-child {
  margin-top: 10px;
}

.select_:last-child {
  margin-bottom: 10px;
}

.select_ {
  padding: 15px 30px;
  color: #1EBCBF;
  cursor: pointer;
}

.select_con {
  position: absolute;
  width: 130%;
  height: 200px;
  left: 0;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  overflow: scroll;
  transition: all 0.3s;
}


.b_input ._input {
  width: 100%;
  height: 100%;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  line-height: 46px;
  font-size: 18px;
}

.b_input {
  position: relative;
  width: 150px;
  height: 46px;
  margin-left: 20px;
}


.overview_left {
  margin-right: 14px;
}

.text {
  font-size: 24px;
  color: #30D6C2;
  margin-bottom: 5px;
}

.text_tip {
  font-size: 14px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area_content_ {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}
</style>
